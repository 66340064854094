<template>
  <b-card no-body>
    <b-card-body
      class="pb-1"
      style="box-shadow: #284356 0px 0px 5px -1px;; border-radius: 6px;"
    >
      <b-row>
        <b-col
          md="7"
          class="d-flex"
        >
          <b-avatar
            class="mb-1 mr-1"
            :variant="`light-${color}`"
            size="60"
          >
            <feather-icon
              size="30"
              :icon="icon"
            />
          </b-avatar>
          <div class="truncate">
            <span class="h4 font-weight-bolder text-black">{{ $t(statisticTitle) }}</span>
            <h5 class="mb-25 text-black">
              {{ statistic }}
            </h5>
          </div>
        </b-col>
        <!-- :style="{backgroundImage: `url(${require('@/assets/images/background/5836.jpg')})`}" -->
        <b-col
          md="5"
          class="text-right"
        >
          <b-button
            v-permission="permission"
            :variant="`outline-${color}`"
            class="text-nowrap"
            style="margin-top: 10px;"
            @click="
              (v) => {
                this.$router.push({ name: routeName });
              }
            "
          >
            {{ $t('new') }}
            <feather-icon
              class="ml-50"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    /> -->
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'; // BAvatar
// import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';
import { areaChartOptions } from './chartOptions';

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardBody,
    // BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    permission: {
      type: String,
      default: ''
    }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions));
        options.theme.monochrome.color = $themeColors[this.color];
        return options;
      }
      return this.chartOptions;
    },
  },
};
</script>
